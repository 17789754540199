import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 10px;

  a {
    color: ${props => props.theme.colors.tag};
    margin-right: 5px;
    font-size: 0.9em;
    text-decoration: none;
  }
`
