import styled from 'styled-components'

export const Container = styled.article`
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const PostCardTitle = styled.h2`
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
  }
`
