import React from 'react'
import Link from 'next/link'
import { Container, PostCardTitle } from './style'
import { Post } from '../../types/post'
import PostCardDate from '../postCardDate/index'
import PostTags from '../postTags'

type PostCardProps = {
  post: Post
}

const PostCard: React.FC<PostCardProps> = ({ post }) => {
  const { title, createdAt, description, slug } = post
  return (
    <Container>
      <PostCardTitle>
        <Link href="/[slug]" as={`/${slug}`}>
          {title}
        </Link>
      </PostCardTitle>
      <PostTags tags={post.tags} />
      <PostCardDate date={createdAt} />
      <p>{description}</p>
    </Container>
  )
}

export default PostCard
