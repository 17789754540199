import React from 'react'
import { Container } from './style'

type PostTagsProps = {
  tags: string[]
}

const PostTags: React.FC<PostTagsProps> = ({ tags }) => {
  return (
    <Container>
      {tags.map(tag => (
        <a key={tag} href={`/tag/${tag}`}>
          #{tag}
        </a>
      ))}
    </Container>
  )
}

export default PostTags
