import React from 'react'
import { DateTime } from 'luxon'
import { Container } from './style'

type PostDateProps = {
  date: string
}

const PostDate: React.FC<PostDateProps> = ({ date }) => {
  const formattedDate = DateTime.fromISO(date).toLocaleString(
    DateTime.DATE_FULL
  )
  return (
    <Container>
      <time dateTime={date}>{formattedDate}</time>
    </Container>
  )
}

export default PostDate
